<template>
  <footer class="icp-footer">
    <a :href="icpLink" target="_blank" rel="noopener">
      {{ icpText }}
    </a>
  </footer>
</template>

<script>
export default {
  name: 'IcpFooter',
  data() {
    return {
      icpLink: 'https://beian.miit.gov.cn/',
      icpText: ''
    }
  },
  mounted() {
    this.setIcpInfo()
  },
  methods: {
    setIcpInfo() {
      const hostname = window.location.hostname
      
      // 根据不同域名设置不同的备案号
      switch(hostname) {
        case 'guoyaai.com':
          this.icpText = '蜀ICP备2024067012号-1'
          break
        case 'tktkai.cn':
          this.icpText = '蜀ICP备2024067012号-2'
          break
        default:
          this.icpText = '正在备案中' // 默认备案号
      }
    }
  }
}
</script>

<style scoped>
.icp-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  background-color: var(--el-bg-color);
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.icp-footer a {
  color: var(--el-text-color-secondary);
  text-decoration: none;
  font-size: 14px;
}

.icp-footer a:hover {
  color: var(--el-text-color-primary);
}

/* 适配暗黑模式 */
html.dark .icp-footer {
  background-color: var(--el-bg-color-overlay);
}
</style> 