<template>
  <div class="classify-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="搜索">
              <el-input v-model="searchForm.search" placeholder="搜索关键词"></el-input>
            </el-form-item>
            <el-form-item label="类型">
              <el-select v-model="searchForm.type" placeholder="选择节点类型" style="width: 200px;">
                <el-option
                  v-for="type in classifyTypes"
                  :key="type.value"
                  :label="type.key"
                  :value="type.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="排序字段">
              <el-select v-model="searchForm.sortBy" placeholder="选择排序字段" style="width: 200px;">
                <el-option label="名称" value="name"></el-option>
                <el-option label="类型" value="type"></el-option>
                <el-option label="层级" value="level"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否降序">
              <el-switch v-model="searchForm.sortDesc"></el-switch>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchNodes">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button type="primary" @click="openCreateDialog()">新增根节点</el-button>
        </div>
      </div>
    </el-card>

    <el-card class="tree-card">
      <el-tree
        v-if="treeData && treeData.length > 0"
        :data="treeData"
        :props="defaultProps"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
      >
        <template #default="{ node, data }">
          <el-tooltip
            :content="`创建时间: ${formatTimestamp(data.base_info.created_at)}<br>更新时间: ${formatTimestamp(data.base_info.updated_at)}<br>描述: ${data.desc}<br>年龄范围: ${data.age_range}<br>费用: ${data.fee}元`"
            placement="top"
            :raw-content="true"
          >
            <div class="custom-tree-node">
              <div class="node-info">
                <el-image
                  v-if="data.pic"
                  :src="data.pic"
                  :preview-src-list="[data.pic]"
                  fit="cover"
                  class="node-image"
                  @click.stop
                  :preview-teleported="true"
                  :initial-index="0"
                  :hide-on-click-modal="false"
                ></el-image>
                <span class="node-name">{{ data.name }}</span>
                <el-tag size="small" class="node-type">{{ getTypeNameById(data.type) }}</el-tag>
                <el-tag size="small" type="info" class="node-level">层级: {{ data.level }}</el-tag>
                <el-tag size="small" type="success" class="node-fee">{{ data.fee }}元</el-tag>
                <el-tag
                  v-for="(tag, index) in getDisplayTags(data.tags)"
                  :key="index"
                  size="small"
                  class="node-tag"
                >{{ tag }}</el-tag>
                <el-button v-if="data.tags && data.tags.split(',').length > 3" size="small" @click="showAllTags(data.tags)">查看全部标签</el-button>
              </div>
              <div class="node-actions">
                <el-button size="small" @click="openCreateDialog(data)">添加子节点</el-button>
                <el-button size="small" @click="editNode(data)">编辑</el-button>
                <el-button size="small" type="danger" @click="removeNode(node, data)">删除</el-button>
              </div>
            </div>
          </el-tooltip>
        </template>
      </el-tree>
      <el-empty v-else description="暂无数据"></el-empty>
    </el-card>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="pagination"
    >
    </el-pagination>

    <el-dialog :title="dialogTitle" v-model="dialogVisible" width="50%">
      <el-form :model="nodeForm" label-width="100px">
        <el-form-item label="名称">
          <el-input v-model="nodeForm.name"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="nodeForm.type">
            <el-option
              v-for="type in classifyTypes"
              :key="type.value"
              :label="type.key"
              :value="type.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="nodeForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="年龄范围">
          <el-input v-model="nodeForm.age_range" placeholder="如: 3-6岁"></el-input>
        </el-form-item>
        <el-form-item label="图片封面">
          <FileUpload
            :fileType="getFileTypeValue('封面')"
            buttonText="上传图片"
            accept="image/*"
            :limit="1"
            :maxSize="10"
            tip="支持常见图片格式，大小不超过10MB"
            @upload-success="handleAvatarSuccess"
            :fileList="picFileList"
          />
          <el-image 
            v-if="nodeForm.pic" 
            :src="nodeForm.pic" 
            style="width: 100px; height: 100px; margin-top: 10px;"
            :preview-src-list="[nodeForm.pic]"
          ></el-image>
        </el-form-item>
        <el-form-item label="项目标签">
          <el-input v-model="nodeForm.tags" placeholder="多个标签用逗号分隔"></el-input>
        </el-form-item>
        <el-form-item label="费用">
          <el-input-number v-model="nodeForm.fee" :min="0"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitNode">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get, post, put, del } from '@/utils/request'
import { getConstantByKey, fetchAndCacheConstants } from '@/utils/constants'
import FileUpload from '@/components/FileUpload.vue'

export default {
  name: 'ClassifyManagement',
  components: {
    FileUpload
  },
  setup() {
    const picFileList = ref([])
    
    const treeData = ref([])
    const dialogVisible = ref(false)
    const dialogTitle = ref('新增节点')
    const nodeForm = reactive({
      base_info: {
        id: null,
      },
      name: '',
      type: '',
      parentId: null,
      desc: '',
      age_range: '',
      pic: '',
      tags: '',
      fee: 0
    })
    const classifyTypes = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const searchForm = reactive({
      search: '',
      parentId: '',
      type: '',
      sortBy: 'id',
      sortDesc: false,
      minFee: null,
      maxFee: null,
      age_range: ''
    })

    const defaultProps = {
      children: 'children',
      label: 'name'
    }

    const fetchTreeData = async () => {
      try {
        const res = await get('/auth/admin/classify/tree-from-node')
        treeData.value = res.data || []
      } catch (error) {
        console.error('获取分类树失败:', error)
        ElMessage.error('获取分类树失败')
        treeData.value = []
      }
    }

    const getTypeNameById = (typeId) => {
      if (!classifyTypes.value || classifyTypes.value.length === 0) return '未知类型'
      const type = classifyTypes.value.find(t => t.value === typeId)
      return type ? type.key : '未知类型'
    }

    const formatTimestamp = (timestamp) => {
      if (!timestamp) return '未知时间'
      const date = new Date(timestamp * 1000)
      return date.toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' })
    }

    const openCreateDialog = (parentNode = null) => {
      dialogTitle.value = parentNode ? '新增子节点' : '新增根节点'
      Object.assign(nodeForm, {
        base_info: {
          id: null
        },
        name: '',
        type: '',
        parentId: parentNode ? parentNode.base_info.id : null,
        desc: '',
        age_range: '',
        pic: '',
        tags: '',
        fee: 0
      })
      picFileList.value = []
      dialogVisible.value = true
    }

    const editNode = (data) => {
      dialogTitle.value = '编辑节点'
      Object.assign(nodeForm, {
        base_info: {
          id: data.base_info.id
        },
        name: data.name,
        type: data.type,
        parentId: data.parentID,
        desc: data.desc,
        age_range: data.age_range,
        pic: data.pic,
        tags: data.tags,
        fee: data.fee
      })
      picFileList.value = nodeForm.pic ? [{ name: '当前图片', url: nodeForm.pic }] : []
      dialogVisible.value = true
    }

    const removeNode = (node, data) => {
      ElMessageBox.confirm('确定要删除这个节点吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await del(`/auth/admin/classify/node/${data.base_info.id}`)
          ElMessage.success('删除成功')
          fetchTreeData()
        } catch (error) {
          console.error('删除失败:', error)
          ElMessage.error('删除失败')
        }
      }).catch(() => {})
    }

    const submitNode = async () => {
      try {
        if (nodeForm.base_info.id) {
          await put(`/auth/admin/classify/node/${nodeForm.base_info.id}`, {
            ...nodeForm,
            pic: nodeForm.pic
          })
          fetchTreeData()
          ElMessage.success('更新成功')
        } else {
          const res = await post('/auth/admin/classify/node', {
            ...nodeForm,
            pic: nodeForm.pic
          })
          ElMessage.success('创建成功')
          if (nodeForm.parentId) {
            const parentNode = findNodeById(treeData.value, nodeForm.parentId)
            if (parentNode) {
              if (!parentNode.children) {
                parentNode.children = []
              }
              parentNode.children.push(res.data)
            }
          } else {
            treeData.value.push(res.data)
          }
        }
        dialogVisible.value = false
      } catch (error) {
        console.error('操作失败:', error)
        ElMessage.error('操作失败')
      }
    }

    const findNodeById = (nodes, id) => {
      for (const node of nodes) {
        if (node.base_info.id === id) {
          return node
        }
        if (node.children) {
          const found = findNodeById(node.children, id)
          if (found) {
            return found
          }
        }
      }
      return null
    }

    const searchNodes = () => {
      currentPage.value = 1
      fetchTreeData()
    }

    const resetSearch = () => {
      Object.assign(searchForm, {
        search: '',
        parentId: '',
        type: '',
        sortBy: 'id',
        sortDesc: false,
        minFee: null,
        maxFee: null,
        age_range: ''
      })
      currentPage.value = 1
      fetchTreeData()
    }

    const handleSizeChange = (val) => {
      pageSize.value = val
      fetchTreeData()
    }

    const handleCurrentChange = (val) => {
      currentPage.value = val
      fetchTreeData()
    }

    const getDisplayTags = (tags) => {
      if (!tags) return []
      const tagArray = tags.split(',')
      return tagArray.slice(0, 3)
    }

    const showAllTags = (tags) => {
      ElMessageBox.alert(tags, '所有标签', {
        confirmButtonText: '确定'
      })
    }

    const handleAvatarSuccess = (response) => {
      console.log('Upload response:', response)
      if (response.code === 200 && response.data && response.data.url) {
        nodeForm.pic = response.data.url
        picFileList.value = [{ name: '当前图片', url: response.data.url }]
        ElMessage.success('图片上传成功')
      } else {
        console.error('Invalid response from file upload:', response)
        ElMessage.error(response.msg || '图片上传失败，请重试')
      }
    }

    const fileTypes = ref(getConstantByKey('fileTypes') || [])
    const getFileTypeValue = (typeName) => {
      const fileTypes = getConstantByKey('fileTypes')
      if (!fileTypes || fileTypes.length === 0) {
        console.error('Failed to load fileTypes')
        ElMessage.error('文件类型配置加载失败')
        return null
      }

      const type = fileTypes.find(t => t.key === typeName)
      if (!type) {
        console.error(`File type "${typeName}" not found`)
        ElMessage.error('未找到指定的文件类型')
        return null
      }

      return type.value
    }

    onMounted(async () => {
      try {
        await fetchAndCacheConstants()
        classifyTypes.value = getConstantByKey('classifyTypes') || []
        fileTypes.value = getConstantByKey('fileTypes') || []
        fetchTreeData()
      } catch (error) {
        console.error('Failed to load constants:', error)
        ElMessage.error('加载配置信息失败')
      }
    })

    return {
      treeData,
      dialogVisible,
      dialogTitle,
      nodeForm,
      classifyTypes,
      currentPage,
      pageSize,
      total,
      searchForm,
      openCreateDialog,
      editNode,
      removeNode,
      submitNode,
      getTypeNameById,
      formatTimestamp,
      searchNodes,
      resetSearch,
      handleSizeChange,
      handleCurrentChange,
      defaultProps,
      getDisplayTags,
      showAllTags,
      handleAvatarSuccess,
      fileTypes,
      getFileTypeValue,
      picFileList
    }
  }
}
</script>

<style scoped>
.classify-management {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mb-4 {
  margin-bottom: 1rem;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  align-items: center;
}
.search-form .el-form-item {
  margin-bottom: 0;
  margin-right: 1rem;
}
.button-area {
  display: flex;
  gap: 1rem;
}
.tree-card {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
}
.pagination {
  margin-top: auto;
  padding: 1rem 0;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px 0;
  width: 100%;
}
.node-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.node-name {
  font-size: 16px;
  font-weight: bold;
}
.node-type, .node-level {
  font-size: 12px;
}
.node-actions {
  display: flex;
  gap: 10px;
}
:deep(.el-tree-node__content) {
  height: auto;
  padding: 5px 0;
}
:deep(.el-tree-node) {
  position: relative;
}
:deep(.el-tree-node__children) {
  padding-left: 20px;
}
:deep(.el-tree-node__expand-icon) {
  padding: 12px;
}
:deep(.el-tree) {
  background-color: transparent;
}
:deep(.el-tree-node__content:hover) {
  background-color: #323334;
}
.node-image {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  transition: transform 0.3s;
  position: relative;
  z-index: 1;
}

.node-image:hover {
  transform: scale(1.1);
  z-index: 2;
}

.node-fee {
  margin-left: 10px;
}
.node-tag {
  margin-left: 5px;
}
.avatar-uploader .avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}
.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  text-align: center;
}
:deep(.el-image-viewer__wrapper) {
  z-index: 2100;
}
</style>
