<template>
  <el-container class="layout-container">
    <el-aside :width="isCollapse ? '64px' : '240px'" class="aside">
      <div class="logo">
        <img src="../assets/logo.png" alt="Logo" />
        <span v-show="!isCollapse">管理系统</span>
      </div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-vertical"
        :collapse="isCollapse"
        @select="handleSelect"
        :background-color="isDarkTheme ? '#1f2d3d' : '#fff'"
        :text-color="isDarkTheme ? '#b2bec3' : '#2c3e50'"
        :active-text-color="isDarkTheme ? '#409EFF' : '#1890ff'"
      >
        <el-menu-item index="/topics">
          <el-icon><Document /></el-icon>
          <template #title>题目管理</template>
        </el-menu-item>
        <el-menu-item index="/classify">
          <el-icon><FolderOpened /></el-icon>
          <template #title>项目节点管理</template>
        </el-menu-item>
        <el-sub-menu index="/users">
          <template #title>
            <el-icon><User /></el-icon>
            <span>用户管理</span>
          </template>
          <el-menu-item index="/users">用户列表</el-menu-item>
          <el-menu-item index="/roles">角色管理</el-menu-item>
          <el-menu-item index="/permissions" v-if="isSuperAdmin">权限列表</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="/system">
          <template #title>
            <el-icon><Setting /></el-icon>
            <span>系统配置</span>
          </template>
          <el-menu-item index="/system/basic">基础设置</el-menu-item>
          <el-menu-item index="/system/permission">权限配置</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="/settings">
          <el-icon><Setting /></el-icon>
          <template #title>系统设置</template>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container class="main-container">
      <el-header class="main-header">
        <div class="header-left">
          <el-icon class="collapse-icon" @click="toggleCollapse">
            <Fold v-if="!isCollapse" />
            <Expand v-else />
          </el-icon>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ currentMenuTitle }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="header-right">
          <el-badge :value="3" class="notification-icon">
            <el-icon><Bell /></el-icon>
          </el-badge>
          <el-switch
            v-model="isDarkTheme"
            @change="toggleTheme"
            class="theme-switch"
          >
            <template #active-icon>
              <el-icon><Moon /></el-icon>
            </template>
            <template #inactive-icon>
              <el-icon><Sunny /></el-icon>
            </template>
          </el-switch>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <el-avatar :size="32" :src="userAvatar" />
              {{ userNickname }}
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>{{ userPhone }}</el-dropdown-item>
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Document, FolderOpened, User, Setting, Fold, Expand, Bell, Moon, Sunny } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { isSuperAdmin } from '@/utils/constants'

export default {
  name: 'Layout',
  components: {
    Document,
    FolderOpened,
    User,
    Setting,
    Fold,
    Expand,
    Bell,
    Moon,
    Sunny
  },
  setup() {
    const router = useRouter()
    const activeIndex = ref(router.currentRoute.value.path)
    const isCollapse = ref(false)
    const isDarkTheme = ref(localStorage.getItem('theme') === 'dark')
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')

    const currentMenuTitle = computed(() => {
      switch (activeIndex.value) {
        case '/topics': return '题目管理'
        case '/classify': return '分类节点管理'
        case '/users': return '用户管理'
        case '/roles': return '角色管理'
        case '/permissions': return '权限列表'
        case '/system/basic': return '基础设置'
        case '/system/permission': return '权限配置'
        case '/settings': return '系统设置'
        default: return '后台管理系统'
      }
    })

    const handleSelect = (key) => {
      router.push(key)
      activeIndex.value = key
    }

    const toggleCollapse = () => {
      isCollapse.value = !isCollapse.value
    }

    const toggleTheme = (value) => {
      if (value) {
        document.documentElement.classList.add('dark')
        localStorage.setItem('theme', 'dark')
      } else {
        document.documentElement.classList.remove('dark')
        localStorage.setItem('theme', 'light')
      }
    }

    // 初始化主题
    watch(isDarkTheme, (newValue) => {
      toggleTheme(newValue)
    }, { immediate: true })

    const handleCommand = (command) => {
      if (command === 'logout') {
        localStorage.removeItem('userInfo')
        localStorage.removeItem('userIdentities')
        router.push('/login')
        ElMessage.success('退出登录成功')
      }
    }

    return {
      activeIndex,
      isCollapse,
      isDarkTheme,
      currentMenuTitle,
      userAvatar: userInfo.avatarPath || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      userNickname: userInfo.nickName || '用户',
      userPhone: userInfo.phone || '未设置手机号',
      handleSelect,
      toggleCollapse,
      toggleTheme,
      handleCommand,
      isSuperAdmin
    }
  }
}
</script>
<style>
/* 全局样式 */
:root {
  --el-color-primary: #409EFF;
}

html.dark {
  --el-color-primary: #409EFF;
  
  /* 背景色 */
  --el-bg-color: #1f2d3d;
  --el-bg-color-page: #0f1620;
  --el-bg-color-overlay: #1d2633;

  /* 文字颜色 */
  --el-text-color-primary: #E5EAF3;
  --el-text-color-regular: #CFD3DC;
  --el-text-color-secondary: #A3A6AD;
  --el-text-color-placeholder: #8D9095;
  --el-text-color-disabled: #6C6E72;

  /* 边框颜色 */
  --el-border-color: #363B48;
  --el-border-color-light: #363B48;
  --el-border-color-lighter: #363B48;
  --el-border-color-extra-light: #363B48;

  /* 填充色 */
  --el-fill-color: #262727;
  --el-fill-color-light: #1d2633;
  --el-fill-color-lighter: #262727;
  --el-fill-color-extra-light: #2b2b2b;
  --el-fill-color-blank: var(--el-bg-color);

  /* 其他 */
  --el-mask-color: rgba(0, 0, 0, 0.8);
  --el-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  --el-box-shadow-light: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}

body {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

/* Layout 相关样式 */
.layout-container {
  height: 100vh;
  overflow: hidden;
}

.aside {
  transition: width 0.3s;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--el-bg-color-overlay);
  box-shadow: 2px 0 6px rgba(0,21,41,.05);
}

.logo {
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background-color: var(--el-bg-color-overlay);
}

.logo img {
  height: 32px;
  margin-right: 12px;
}

.logo span {
  font-size: 18px;
  font-weight: 600;
  color: var(--el-text-color-primary);
  white-space: nowrap;
}

.el-menu-vertical:not(.el-menu--collapse) {
  width: 240px;
}

.main-container {
  display: flex;
  flex-direction: column;
}

.main-header {
  background-color: var(--el-bg-color);
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px !important;
}

.header-left, .header-right {
  display: flex;
  align-items: center;
}

.collapse-icon {
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.notification-icon {
  margin-right: 20px;
  cursor: pointer;
}

.theme-switch {
  margin-right: 20px;
}

.el-dropdown-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.el-avatar {
  margin-right: 8px;
}

.el-main {
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--el-bg-color-page);
}

/* Element Plus 组件样式覆盖 */
.el-card {
  border-radius: 8px;
  border: 1px solid var(--el-border-color-light);
  background-color: var(--el-bg-color-overlay);
}

.el-button {
  border-radius: 4px;
}

.el-input__inner, 
.el-select .el-input__inner,
.el-textarea__inner {
  background-color: var(--el-bg-color);
  border-color: var(--el-border-color);
  color: var(--el-text-color-regular);
}

.el-input__inner:hover, 
.el-select .el-input__inner:hover,
.el-textarea__inner:hover {
  border-color: var(--el-border-color-hover);
}

.el-input__inner:focus, 
.el-select .el-input__inner:focus,
.el-textarea__inner:focus {
  border-color: var(--el-color-primary);
}

.el-table {
  background-color: var(--el-bg-color-overlay);
  color: var(--el-text-color-regular);
}

.el-table th,
.el-table tr {
  background-color: var(--el-bg-color-overlay);
}

.el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: var(--el-fill-color-lighter);
}

.el-pagination {
  color: var(--el-text-color-regular);
}

.el-pagination .btn-prev,
.el-pagination .btn-next {
  background-color: var(--el-bg-color);
}

.el-dialog {
  background-color: var(--el-bg-color-overlay);
}

.el-dialog__title {
  color: var(--el-text-color-primary);
}

.el-container {
  padding-bottom: 40px; /* 为固定底部预留空间 */
}

</style>