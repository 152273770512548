<template>
  <div class="role-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="角色名称">
              <el-input v-model="searchForm.name" placeholder="角色名称"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchRoles">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button type="success" @click="openCreateDialog">新增角色</el-button>
        </div>
      </div>
    </el-card>

    <el-card>
      <el-table :data="roles" style="width: 100%">
        <el-table-column prop="name" label="角色名称"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column label="操作" width="250">
          <template #default="scope">
            <el-button size="small" @click="editRole(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" @click="deleteRole(scope.row)" v-if="isSuperAdmin()">删除</el-button>
            <el-button size="small" type="warning" @click="configurePermissions(scope.row)">配置权限</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :total="total"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- 新增/编辑角色对话框 -->
    <el-dialog :title="dialogTitle" v-model="dialogVisible" width="70%">
      <el-form :model="roleForm" label-width="120px">
        <el-form-item label="角色名称">
          <el-input v-model="roleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="roleForm.description"></el-input>
        </el-form-item>
        <el-form-item label="权限" class="permissions-form-item">
          <div class="permissions-container">
            <el-scrollbar height="400px" v-if="Object.keys(groupedPermissions).length">
              <el-checkbox-group v-model="roleForm.permissions">
                <div v-for="(permissions, module) in groupedPermissions" :key="module" class="permission-module">
                  <h3 class="module-title">{{ module }}</h3>
                  <div class="permission-list">
                    <el-checkbox 
                      v-for="permission in permissions" 
                      :key="permission.id" 
                      :label="permission.id"
                      class="permission-item"
                      :class="{ 'selected': roleForm.permissions.includes(permission.id) }"
                    >
                      <div class="permission-info">
                        <span class="permission-name">{{ permission.name }}</span>
                        <span class="permission-description">{{ permission.description }}</span>
                        <el-tag size="small" :type="getMethodTagType(permission.method)">{{ permission.method }}</el-tag>
                      </div>
                    </el-checkbox>
                  </div>
                </div>
              </el-checkbox-group>
            </el-scrollbar>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitRole">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 配置角色权限对话框 -->
    <el-dialog title="配置角色权限" v-model="permissionDialogVisible" width="70%">
      <el-form :model="permissionForm">
        <el-form-item label="权限" class="permissions-form-item">
          <div class="permissions-container">
            <el-scrollbar height="400px">
              <el-checkbox-group v-model="permissionForm.permissions">
                <div v-for="(permissions, module) in groupedPermissions" :key="module" class="permission-module">
                  <h3 class="module-title">{{ module }}</h3>
                  <div class="permission-list">
                    <el-checkbox 
                      v-for="permission in permissions" 
                      :key="permission.id" 
                      :label="permission.id"
                      class="permission-item"
                      :class="{ 'selected': permissionForm.permissions.includes(permission.id) }"
                    >
                      <div class="permission-info">
                        <span class="permission-name">{{ permission.name }}</span>
                        <span class="permission-description">{{ permission.description }}</span>
                        <el-tag size="small" :type="getMethodTagType(permission.method)">{{ permission.method }}</el-tag>
                      </div>
                    </el-checkbox>
                  </div>
                </div>
              </el-checkbox-group>
            </el-scrollbar>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="permissionDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitPermissions">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, watch, onMounted, computed, nextTick } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get, post, put, del } from '@/utils/request'
import { isSuperAdmin } from '@/utils/constants'

export default {
  name: 'RoleManagement',
  setup() {
    const searchForm = reactive({
      name: ''
    })
    const roles = ref([])
    const allPermissions = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const permissionDialogVisible = ref(false)
    const dialogTitle = ref('新增角色')
    const roleForm = reactive({
      id: null,
      name: '',
      description: '',
      permissions: []
    })
    const permissionForm = reactive({
      roleId: null,
      permissions: []
    })

    const groupedPermissions = computed(() => {
      const grouped = {}
      allPermissions.value.forEach(permission => {
        if (!grouped[permission.module]) {
          grouped[permission.module] = []
        }
        grouped[permission.module].push(permission)
      })
      return grouped
    })

    const getMethodTagType = (method) => {
      const types = {
        GET: '',
        POST: 'success',
        PUT: 'warning',
        DELETE: 'danger'
      }
      return types[method] || 'info'
    }

    const searchRoles = async () => {
      try {
        const res = await get('/auth/admin/permission/roles', {
          page: currentPage.value,
          pageSize: pageSize.value,
          name: searchForm.name
        })
        roles.value = res.data.items
        total.value = res.data.total
      } catch (error) {
        ElMessage.error('获取角色列表失败')
      }
    }

    const resetSearch = () => {
      searchForm.name = ''
      searchRoles()
    }

    const openCreateDialog = () => {
      dialogTitle.value = '新增角色'
      Object.assign(roleForm, {
        id: null,
        name: '',
        description: '',
        permissions: []
      })
      nextTick(() => {
        dialogVisible.value = true
      })
    }

    const editRole = (row) => {
      dialogTitle.value = '编辑角色'
      Object.assign(roleForm, row)
      dialogVisible.value = true
    }

    const deleteRole = (row) => {
      ElMessageBox.confirm('确定要删除这个角色吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await del(`/auth/admin/permission/role/${row.id}`)
          ElMessage.success('删除成功')
          searchRoles()
        } catch (error) {
          ElMessage.error('删除失败')
        }
      }).catch(() => {})
    }

    const submitRole = async () => {
      try {
        const roleData = {
          name: roleForm.name,
          description: roleForm.description,
          permissions: roleForm.permissions
        }
        
        if (roleForm.id) {
          await put(`/auth/admin/permission/role/${roleForm.id}`, roleData)
          ElMessage.success('更新成功')
        } else {
          await post('/auth/admin/permission/role', roleData)
          ElMessage.success('创建成功')
        }
        dialogVisible.value = false
        searchRoles()
      } catch (error) {
        ElMessage.error('操作失败')
      }
    }

    const configurePermissions = async (role) => {
      permissionForm.roleId = role.id
      try {
        const res = await get('/auth/admin/permission/role-permissions', { role_id: role.id })
        permissionForm.permissions = res.data.map(p => p.id)
        permissionDialogVisible.value = true
      } catch (error) {
        ElMessage.error('获取角色权限失败')
      }
    }

    const submitPermissions = async () => {
      try {
        await post('/auth/admin/permission/assign-permissions', {
          role_id: permissionForm.roleId,
          permission_ids: permissionForm.permissions
        })
        ElMessage.success('权限配置成功')
        permissionDialogVisible.value = false
      } catch (error) {
        ElMessage.error('权限配置失败')
      }
    }

    const handleSizeChange = (val) => {
      pageSize.value = val
      currentPage.value = 1
      searchRoles()
    }

    const handleCurrentChange = (val) => {
      currentPage.value = val
      searchRoles()
    }

    const fetchAllPermissions = async () => {
      try {
        const params = {
          sortBy: 'module',
          sortDesc: false
        }
        const res = await get('/auth/admin/permission/all', params)
        allPermissions.value = res.data
      } catch (error) {
        ElMessage.error('获取权限列表失败')
      }
    }

    onMounted(async () => {
      await fetchAllPermissions()
      await searchRoles()
    })

    watch([currentPage, pageSize], () => {
      searchRoles()
    })

    return {
      searchForm,
      roles,
      allPermissions,
      currentPage,
      pageSize,
      total,
      dialogVisible,
      permissionDialogVisible,
      dialogTitle,
      roleForm,
      permissionForm,
      searchRoles,
      resetSearch,
      openCreateDialog,
      editRole,
      deleteRole,
      submitRole,
      configurePermissions,
      submitPermissions,
      handleSizeChange,
      handleCurrentChange,
      isSuperAdmin,
      groupedPermissions,
      getMethodTagType
    }
  }
}
</script>

<style scoped>
.role-management {
  height: 100%;
}
.mb-4 {
  margin-bottom: 1rem;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.button-area {
  display: flex;
  gap: 0.5rem;
}
.pagination-container {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
.permissions-form-item {
  width: 100%;
}

.permissions-container {
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
}

.permission-module {
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
}

.module-title {
  margin-bottom: 10px;
  color: #409EFF;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.permission-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.permission-item {
  display: block;
  margin-bottom: 10px;
  width: 100%;
}

.permission-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.permission-name {
  font-weight: bold;
  margin-right: 10px;
  flex: 0 0 150px;
}

.permission-description {
  color: #666;
  margin-right: 10px;
  flex: 1;
}

.selected {
  color: #409EFF;
}

.el-checkbox {
  width: 100%;
  margin-right: 0;
  display: flex;
  align-items: flex-start;
}

.el-checkbox__label {
  width: 100%;
}

:deep(.el-checkbox__input) {
  align-self: flex-start;
  margin-top: 3px;
}
</style>