<template>
  <div class="topic-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="标题" style="width: 200px;">
              <el-input v-model="searchForm.title" placeholder="题目标题"></el-input>
            </el-form-item>
            <el-form-item label="题目类型" style="width: 200px;">
              <el-select v-model="searchForm.topic_type" placeholder="选择题目类型">
                <el-option
                  v-for="type in topicTypes"
                  :key="type.value"
                  :label="type.key"
                  :value="type.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分类ID" style="width: 200px;">
              <el-input v-model="searchForm.classify_id" placeholder="分类ID"></el-input>
            </el-form-item>
            <el-form-item label="状态" style="width: 200px;">
              <el-select v-model="searchForm.status" placeholder="选择状态">
                <el-option label="启用" :value="1"></el-option>
                <el-option label="禁用" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchTopics">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button type="success" @click="openCreateDialog">新增题目</el-button>
        </div>
      </div>
    </el-card>

    <el-card class="topic-list-card">
      <el-table v-if="topics && topics.length > 0" :data="topics" style="width: 100%">
        <el-table-column prop="title" label="题目名称"></el-table-column>
        <el-table-column prop="topic_type" label="题目类型">
          <template #default="scope">
            {{ getTopicTypeName(scope.row.topic_type) }}
          </template>
        </el-table-column>
        <el-table-column label="题目选项">
          <template #default="scope">
            {{ scope.row.status === 1 ? '启用' : '禁用' }}
            <el-button 
              v-if="scope.row.option_list && scope.row.option_list.length > 0"
              link 
              type="primary" 
              @click="showOptions(scope.row.option_list)"
            >
              查看选项
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间">
          <template #default="scope">
            {{ formatDate(scope.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-button link type="primary" @click="showMoreInfo(scope.row)">更多信息</el-button>
            <el-button link type="primary" @click="editTopic(scope.row)">编辑</el-button>
            <el-button link type="danger" @click="deleteTopic(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-empty v-else description="暂无数据"></el-empty>
    </el-card>

    <div class="pagination-container">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

    <!-- 新增/编辑题目对话框 -->
    <el-dialog
      :title="dialogTitle"
      v-model="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleDialogClose"
    >
      <el-scrollbar height="60vh">
        <el-form :model="topicForm" label-width="120px">
          <el-divider content-position="left">基本信息</el-divider>
          <el-form-item label="标题" required>
            <el-input v-model="topicForm.title"></el-input>
          </el-form-item>

          <el-divider content-position="left">项目信息</el-divider>
          <el-form-item label="题目类型" required>
            <el-select v-model="topicForm.topic_type" @change="handleTopicTypeChange">
              <el-option
                v-for="type in topicTypes"
                :key="type.value"
                :label="type.key"
                :value="type.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属项目">
            <div class="project-selection">
              <div class="selected-tags">
                <el-tag
                  v-for="classify in topicForm.classify_id_list"
                  :key="classify.classify_id"
                  closable
                  @close="removeClassify(classify.classify_id)"
                  class="mr-1 mb-1"
                >
                  {{ classify.classify_name || getClassifyName(classify.classify_id) }}
                </el-tag>
              </div>
              <el-cascader
                v-model="selectedClassifies"
                :options="classifyTree"
                :props="{
                  multiple: true,
                  checkStrictly: true,
                  emitPath: false,
                  label: 'name',
                  value: 'id',
                  children: 'children'
                }"
                clearable
                collapse-tags
                @change="handleClassifyChange"
                placeholder="请选择所属项目"
              ></el-cascader>
            </div>
          </el-form-item>

          <!-- 选择题和判断题的选项 -->
          <template v-if="topicForm.topic_type === getTopicTypeValue('单选题') || topicForm.topic_type === getTopicTypeValue('多选题')">
            <el-form-item
              v-for="(option, index) in topicForm.options"
              :key="index"
              :label="`选项 ${index + 1}`"
            >
              <div class="option-row">
                <el-input 
                  v-model="option.option" 
                  style="width: 60%;"
                ></el-input>
                <el-select v-model="option.option_type" style="width: 30%;">
                  <el-option
                    v-for="type in optionTypes"
                    :key="type.value"
                    :label="type.key"
                    :value="type.value"
                  ></el-option>
                </el-select>
                <el-button @click="removeOption(index)" type="danger" icon="el-icon-delete"></el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button @click="addOption" type="primary" icon="el-icon-plus">添加选项</el-button>
            </el-form-item>
          </template>
          
          <!-- 打分题的分数范围 -->
          <el-form-item v-if="topicForm.topic_type === getTopicTypeValue('打分制题')" label="分数范围">
            <div v-if="dialogVisible">
              <el-slider
                v-model="topicForm.score_range"
                range
                :min="0"
                :max="3000"
                :step="1"
                show-input
                input-size="small"
                style="width: 200px;"
                @change="updateScoreRange"
              ></el-slider>
            </div>
          </el-form-item>
          
          <el-divider content-position="left">附加资源</el-divider>
          <!-- 上传文件 -->
          <el-form-item label="上传图片">
            <FileUpload
              :fileType="getFileTypeValue('封面')"
              buttonText="上传图片"
              accept="image/*"
              :limit="1"
              :maxSize="10"
              tip="支持常见图片格式，大小不超过10MB"
              @upload-success="handlePicUploadSuccess"
              :fileList="picFileList"
            />
            <el-image v-if="topicForm.pic" :src="topicForm.pic" style="width: 100px; height: 100px; margin-top: 10px;"></el-image>
          </el-form-item>
          <el-form-item label="上传视频">
            <FileUpload
              :fileType="getFileTypeValue('题目视频')"
              buttonText="上传视频"
              accept="video/*"
              :limit="1"
              :maxSize="100"
              tip="支持常见视频格式，大小不超过100MB"
              @upload-success="handleVideoUploadSuccess"
            />
            <video v-if="topicForm.video" :src="topicForm.video" controls style="max-width: 300px;"></video>
          </el-form-item>
          <el-form-item label="上传音频">
            <el-upload
              :action="`${apiBaseUrl}/auth/file/upload`"
              :data="{ fileType: getFileTypeValue('题目音频') }"
              :on-success="handleAudioUploadSuccess"
              :before-upload="beforeUpload"
              :file-list="audioFileList"
              :headers="uploadHeaders"
            >
              <el-button icon="el-icon-upload">点击上传</el-button>
            </el-upload>
            <audio v-if="topicForm.audio" :src="topicForm.audio" controls></audio>
          </el-form-item>
          <el-form-item label="上传文件">
            <el-upload
              :action="`${apiBaseUrl}/auth/file/upload`"
              :data="{ fileType: getFileTypeValue('题目文件') }"
              :on-success="handleFileUploadSuccess"
              :before-upload="beforeUpload"
              :file-list="fileFileList"
              :headers="uploadHeaders"
            >
              <el-button icon="el-icon-upload">点击上传</el-button>
            </el-upload>
            <div v-if="topicForm.file">
              已上传文件：<a :href="topicForm.file" target="_blank">查看文件</a>
            </div>
          </el-form-item>

          <el-divider content-position="left">其他设置</el-divider>
          <el-form-item label="状态">
            <el-switch
              v-model="topicForm.status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
            ></el-switch>
          </el-form-item>
          <el-form-item label="权重">
            <el-input-number v-model="topicForm.weight" :min="0" :max="100"></el-input-number>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleDialogClose">取 消</el-button>
          <el-button type="primary" @click="submitTopic">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 查看更多信息对话框 -->
    <el-dialog
      title="题目详细信息"
      v-model="moreInfoDialogVisible"
      width="60%"
    >
      <el-descriptions :column="2" border>
        <el-descriptions-item label="题目ID">{{ currentTopic.id }}</el-descriptions-item>
        <el-descriptions-item label="题目标题">{{ currentTopic.title }}</el-descriptions-item>
        <el-descriptions-item label="题目类型">{{ getTopicTypeName(currentTopic.topic_type) }}</el-descriptions-item>
        <el-descriptions-item label="题目内容">{{ currentTopic.content }}</el-descriptions-item>
        <el-descriptions-item label="所属分类">
          <el-tag
            v-for="classify in currentTopic.classify_id"
            :key="classify.id"
            class="mr-1 mb-1"
          >
            {{ classify.classify_name }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">{{ formatDate(currentTopic.created_at) }}</el-descriptions-item>
        <el-descriptions-item label="更新时间">{{ formatDate(currentTopic.updated_at) }}</el-descriptions-item>
        <el-descriptions-item label="状态">{{ currentTopic.status === 1 ? '启用' : '禁用' }}</el-descriptions-item>
        <el-descriptions-item label="权重">{{ currentTopic.weight }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>

    <!-- 查看选项对话框 -->
    <el-dialog
      title="题目选项"
      v-model="optionsDialogVisible"
      width="50%"
    >
      <el-table :data="currentOptions" style="width: 100%">
        <el-table-column prop="option" label="选项内容"></el-table-column>
        <el-table-column prop="option_type" label="选项类型">
          <template #default="scope">
            {{ getOptionTypeName(scope.row.option_type) }}
          </template>
        </el-table-column>
        <el-table-column prop="option_content" label="选项附加内容"></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 查看所有分类对话框 -->
    <el-dialog
      title="所有分类"
      v-model="classifyDialogVisible"
      width="50%"
    >
      <el-tag
        v-for="classify in currentClassifies"
        :key="classify.id"
        class="mr-1 mb-1"
      >
        {{ classify.classify_name }}
      </el-tag>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get, post, put, del } from '@/utils/request'
import env from '../../.env.js'; // 引入环境变量
import FileUpload from '@/components/FileUpload.vue'
import { getConstantByKey } from '@/utils/constants'

export default {
  name: 'TopicManagement',
  components: {
    FileUpload
  },
  setup() {
    const apiBaseUrl = env.BASE_URL; // 获取基础URL

    // 如果需要添加认证Token等，可以在 headers 中添加
      // 从缓存中获取token
  const userInfoString = localStorage.getItem('userInfo');
  // 将JSON字符串转换为JSON对象
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  // 如果有token，则添加到header中
  const uploadHeaders = {}
  if (userInfo && userInfo.token) {
    uploadHeaders.Authorization = userInfo.token;
  }

    const searchForm = reactive({
      title: '',
      topic_type: '',
      classify_id: '',
      status: ''
    })
    const topics = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const dialogTitle = ref('新增题目')
    const topicForm = reactive({
      id: null,
      title: '',
      topic_type: '',
      content: '',
      classify_id_list: [],
      options: [],
      score: 0,
      score_range: [0, 3000], // 初始化为一个有效的范围
      pic: '',
      video: '',
      audio: '',
      file: '',
      status: 1,
      weight: 0
    })

    const topicTypes = ref([])
    const optionTypes = ref([])
    const classifyTree = ref([])
    const classifyMap = ref({})
    const classifyMapReady = ref(false)
    const selectedClassifies = ref([])

    const picFileList = ref([])
    const videoFileList = ref([])
    const audioFileList = ref([])
    const fileFileList = ref([])

    const searchTopics = async () => {
      try {
        const res = await get('/auth/admin/evaluation-topic', {
          params: {
            page: currentPage.value,
            page_size: pageSize.value,
            title: searchForm.title,
            topic_type: searchForm.topic_type,
            classify_id: searchForm.classify_id,
            status: searchForm.status
          }
        })
        topics.value = res.data.topics.map(topic => ({
          id: topic.base_info.id,
          title: topic.title,
          classify_list: topic.classify_list || [],
          topic_type: topic.topic_type,
          option_list: topic.option_list || [],
          score_range: topic.score_range,
          status: topic.status,
          created_at: topic.base_info.created_at,
          updated_at: topic.base_info.updated_at
        }))
        total.value = res.data.total || 0
        console.log(topics.value)
      } catch (error) {
        console.error('获取题目列表失败:', error)
        ElMessage.error('获取题目列表失败')
        topics.value = []
        total.value = 0
      }
    }

    const resetSearch = () => {
      Object.keys(searchForm).forEach(key => {
        searchForm[key] = ''
      })
      searchTopics()
    }

    const openCreateDialog = () => {
      dialogTitle.value = '新增题目'
      Object.assign(topicForm, {
        id: null,
        title: '',
        topic_type: '',
        content: '',
        classify_id_list: [],
        options: [],
        score: 0,
        score_range: [0, 3000], // 初始化为一个有效的范围
        pic: '',
        video: '',
        audio: '',
        file: '',
        status: 1,
        weight: 0
      })
      selectedClassifies.value = []
      picFileList.value = []
      videoFileList.value = []
      audioFileList.value = []
      fileFileList.value = []
      dialogVisible.value = true
    }

    const editTopic = async (row) => {
      if (!row) return
      dialogTitle.value = '编辑题目'
      
      // 确保 classifyMap 已经准备好
      if (!classifyMapReady.value) {
        await getClassifyTree()
      }

      // 转换 classify_list 为需要的格式，使用 classify_id 和 classify_name
      const classifyIdList = row.classify_list?.map(classify => ({
        classify_id: classify.classify_id, // 使用 classify_id
        classify_name: classify.classify_name // 使用 classify_name
      })).filter(item => item.classify_id) || [] // 确保只包含有效的 classify_id

      Object.assign(topicForm, {
        id: row.id,
        title: row.title,
        topic_type: row.topic_type,
        content: row.content,
        classify_id_list: classifyIdList,
        options: row.option_list ? row.option_list.map(option => ({
          option: option.option,
          option_type: option.option_type,
          option_content: option.option_content
        })) : [],
        score: row.score,
        score_range: row.score_range ? row.score_range.split('-').map(Number) : [0,3000],
        pic: row.pic,
        video: row.video,
        audio: row.audio,
        file: row.file,
        status: row.status,
        weight: row.weight
      })

      // 设置 selectedClassifies 为当前题目的分类 ID 列表，使用 classify_id
      selectedClassifies.value = classifyIdList.map(classify => classify.classify_id)

      // 设置上传文件列表
      picFileList.value = topicForm.pic ? [{ name: '当前图片', url: topicForm.pic }] : []
      videoFileList.value = topicForm.video ? [{ name: '当前视频', url: topicForm.video }] : []
      audioFileList.value = topicForm.audio ? [{ name: '当前音频', url: topicForm.audio }] : []
      fileFileList.value = topicForm.file ? [{ name: '当前文件', url: topicForm.file }] : []

      dialogVisible.value = true
    }

    const deleteTopic = (row) => {
      ElMessageBox.confirm('确定要删除这个题目吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await del(`/auth/admin/evaluation-topic/${row.id}`)
          ElMessage.success('删除成功')
          searchTopics()
        } catch (error) {
          ElMessage.error('删除失败')
        }
      }).catch(() => {})
    }

    const getClassifyTree = async () => {
      try {
        const res = await get('/auth/admin/classify/tree-from-node')
        // 转换数据结构以匹配 el-cascader 的要求
        classifyTree.value = res.data.map(item => ({
          id: item.base_info.id,
          name: item.name,
          value: item.base_info.id,
          label: item.name,
          children: item.children || []
        }))
        updateClassifyMap(classifyTree.value)
        classifyMapReady.value = true
      } catch (error) {
        console.error('获取项目树失败:', error)
        ElMessage.error('获取项目树失败')
      }
    }

    const updateClassifyMap = (tree) => {
      tree.forEach(node => {
        classifyMap.value[node.id] = node.name
        if (node.children) {
          updateClassifyMap(node.children)
        }
      })
    }

    const getClassifyName = (classifyID) => {
      return classifyMap.value[classifyID] || '未知分类'
    }

    const handleTopicTypeChange = () => {
      topicForm.options = []
      topicForm.score = 0
      topicForm.score_range = [0, 3000]
    }

    const addOption = () => {
      topicForm.options.push({
        option: '',
        option_type: 1,
        option_content: ''
      })
    }

    const removeOption = (index) => {
      topicForm.options.splice(index, 1)
    }

    const handleClassifyChange = (value) => {
      if (value && Array.isArray(value)) {
        // 将选中的分类ID转换为对象数组格式
        topicForm.classify_id_list = value.filter(Boolean).map(classifyId => ({
          classify_id: classifyId,
          classify_name: getClassifyName(classifyId)
        }))
      }
    }

    const removeClassify = (classifyId) => {
      const index = topicForm.classify_id_list.findIndex(item => item.classify_id === classifyId)
      if (index > -1) {
        topicForm.classify_id_list.splice(index, 1)
        // 同时更新 selectedClassifies
        const selectedIndex = selectedClassifies.value.indexOf(classifyId)
        if (selectedIndex > -1) {
          selectedClassifies.value.splice(selectedIndex, 1)
        }
      }
    }

    const handlePicUploadSuccess = (response) => {
      if (response && response.data && response.data.url) {
        topicForm.pic = response.data.url
        picFileList.value = [{ name: '当前图片', url: response.data.url }]
      } else {
        console.error('Invalid response from file upload:', response)
        ElMessage.error('图片上传失败，请重试')
      }
    }

    const handleVideoUploadSuccess = (response) => {
      topicForm.video = response.url
    }

    const handleAudioUploadSuccess = (response) => {
      topicForm.audio = response.url
    }

    const handleFileUploadSuccess = (response) => {
      topicForm.file = response.url
    }

    const beforeUpload = (file) => {
      console.log(file)
      // 在这里可以添加文件类型和大小的验证
      return true
    }

    const updateScoreRange = (value) => {
      if (Array.isArray(value) && value.length === 2) {
        topicForm.score_range = value // 确保 scoreRange 始终是一个数组
      }
    }

    const submitTopic = async () => {
      try {
        const topicData = { ...topicForm }
        // classify_id_list 已经是正确的对象数组格式，无需转换

        // 将 score_range 数组转换为字符串
        if (Array.isArray(topicData.score_range)) {
          topicData.score_range = topicData.score_range.join('-')
        }

        // 确保 pic 字段被正确赋值
        topicData.pic = topicForm.pic

        if (topicForm.id) {
          await put(`/auth/admin/evaluation-topic/${topicForm.id}`, topicData)
          ElMessage.success('更新成功')
        } else {
          await post('/auth/admin/evaluation-topic', topicData)
          ElMessage.success('创建成功')
        }
        dialogVisible.value = false
        searchTopics()
      } catch (error) {
        console.error('操作失败:', error)
        ElMessage.error('操作失败')
      }
    }

    const handleSizeChange = (val) => {
      pageSize.value = val
      searchTopics()
    }

    const handleCurrentChange = (val) => {
      currentPage.value = val
      searchTopics()
    }

    const handleDialogClose = (done) => {
      ElMessageBox.confirm('确定要关闭吗？未保存的内容将会丢失。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        dialogVisible.value = false
        done()
      }).catch(() => {
        // 取消关闭
      })
    }

    const getTopicTypeName = (value) => {
      const type = topicTypes.value.find(t => t.value === value)
      return type ? type.key : '未知类型'
    }

    onMounted(async () => {
      topicTypes.value = getConstantByKey('topicTypes') || []
      optionTypes.value = getConstantByKey('topicOptionTypes') || []
      await getClassifyTree() // 等待分类树加载完成
      await searchTopics() // 等待题目列表加载完成
    })

    // 监听 classifyTree 的变化
    watch(classifyTree, () => {
      updateClassifyMap(classifyTree.value)
      classifyMapReady.value = true
    })

    const classifyDialogVisible = ref(false)
    const optionsDialogVisible = ref(false)
    const moreInfoDialogVisible = ref(false)
    const currentClassifies = ref([])
    const currentOptions = ref([])
    const currentTopic = ref({})

    const showAllClassifies = (classifies) => {
      currentClassifies.value = classifies
      classifyDialogVisible.value = true
    }

    const showOptions = (options) => {
      currentOptions.value = options
      optionsDialogVisible.value = true
    }

    const showMoreInfo = (topic) => {
      currentTopic.value = topic
      moreInfoDialogVisible.value = true
    }

    const getOptionTypeName = (value) => {
      const type = optionTypes.value.find(t => t.value === value)
      return type ? type.key : '未知类型'
    }

    const formatDate = (timestamp) => {
      const date = new Date(timestamp * 1000)
      return date.toLocaleString()
    }

    const getTopicTypeValue = (typeName) => {
      const type = topicTypes.value.find(t => t.key === typeName);
      return type ? type.value : null;
    }

    const getOptionTypeValue = (typeName) => {
      const type = optionTypes.value.find(t => t.key === typeName);
      return type ? type.value : null;
    }

    const fileTypes = ref(getConstantByKey('fileTypes') || [])

    const getFileTypeValue = (typeName) => {
      const type = fileTypes.value.find(t => t.key === typeName);
      return type ? type.value : null;
    }

    return {
      apiBaseUrl, // 导出基础URL
      uploadHeaders, // 导出上传头部
      searchForm,
      topics,
      currentPage,
      pageSize,
      total,
      dialogVisible,
      dialogTitle,
      topicForm,
      topicTypes,
      optionTypes,
      classifyTree,
      selectedClassifies,
      searchTopics,
      resetSearch,
      openCreateDialog,
      editTopic,
      deleteTopic,
      handleTopicTypeChange,
      addOption,
      removeOption,
      handleClassifyChange,
      removeClassify,
      getClassifyName,
      handlePicUploadSuccess,
      handleVideoUploadSuccess,
      handleAudioUploadSuccess,
      handleFileUploadSuccess,
      beforeUpload,
      updateScoreRange,
      submitTopic,
      handleSizeChange,
      handleCurrentChange,
      handleDialogClose,
      getTopicTypeName,
      classifyDialogVisible,
      optionsDialogVisible,
      moreInfoDialogVisible,
      currentClassifies,
      currentOptions,
      currentTopic,
      showAllClassifies,
      showOptions,
      showMoreInfo,
      getOptionTypeName,
      formatDate,
      getTopicTypeValue,
      getOptionTypeValue,
      picFileList,
      videoFileList,
      audioFileList,
      fileFileList,
      fileTypes,
      getFileTypeValue
    }
  }
}
</script>

<style scoped>
.topic-management {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mb-4 {
  margin-bottom: 1rem;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.button-area {
  display: flex;
  gap: 0.5rem;
}
.topic-list-card {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
}
.pagination-container {
  margin-top: auto;
  padding: 1rem 0;
}
.el-divider {
  background-color: #f0f2f5;
  margin: 24px 0;
  border-radius: 4px;
}
.el-divider__text {
  background-color: #fff;
  padding: 0 20px;
  font-weight: bold;
  color: #606266;
}
:deep(.el-dialog__body) {
  padding: 0;
}

:deep(.el-scrollbar__view) {
  padding: 20px;
}

.option-row {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.option-row .el-input {
  flex-grow: 1;
}

.option-row .el-select {
  width: 120px;
}

.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}

.project-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.selected-tags {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.el-cascader {
  width: 100%;
}
</style>