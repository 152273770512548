import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 使用 Element Plus 的样式
import 'element-plus/theme-chalk/dark/css-vars.css';  // 添加这行
import router from './router';  // 确保导入 Vue 3 版 router
import store from './store';  // 确保导入 store
import axios from 'axios';
import VueAxios from 'vue-axios';

// 在文件顶部添加这个函数
const debounce = (fn, delay) => {
  let timeoutId = null;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
};

// 在创建 app 实例之前添加这段代码
const handler = debounce(() => {
  const target = document.getElementsByClassName('resize-observer-text')[0];
  if (target && target.textContent.includes('ResizeObserver loop')) {
    target.style.display = 'none';
  }
}, 50);

window.addEventListener('error', handler);

// 创建 Vue 应用实例
const app = createApp(App);

// 使用 VueX, Vue Router, Element Plus 和 Vue Axios
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VueAxios, axios);


// 初始化主题
const theme = localStorage.getItem('theme')
if (theme === 'dark') {
  document.documentElement.classList.add('dark')
} else {
  document.documentElement.classList.remove('dark')
}


// 挂载应用实例
app.mount('#app');