<template>
  <div class="permission-list">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="权限名称">
              <el-input v-model="searchForm.name" placeholder="权限名称"></el-input>
            </el-form-item>
            <el-form-item label="排序字段" style="width: 200px">
              <el-select v-model="searchForm.sortBy" placeholder="选择排序字段">
                <el-option label="名称" value="name"></el-option>
                <el-option label="API路径" value="endpoint"></el-option>
                <el-option label="HTTP方法" value="method"></el-option>
                <el-option label="创建时间" value="createdAt"></el-option>
                <el-option label="更新时间" value="updatedAt"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否降序">
              <el-switch v-model="searchForm.sortDesc"></el-switch>
            </el-form-item>
            <el-form-item label="HTTP方法" style="width: 200px">
              <el-select v-model="searchForm.method" placeholder="选择HTTP方法">
                <el-option label="GET" value="GET"></el-option>
                <el-option label="POST" value="POST"></el-option>
                <el-option label="PUT" value="PUT"></el-option>
                <el-option label="DELETE" value="DELETE"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchPermissions">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </div>
      </div>
    </el-card>

    <el-card>
      <el-table :data="permissions" style="width: 100%">
        <el-table-column prop="name" label="权限名称"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column prop="endpoint" label="API路径"></el-table-column>
        <el-table-column prop="method" label="HTTP方法"></el-table-column>
        <el-table-column prop="createdAt" label="创建时间" :formatter="formatTimestamp"></el-table-column>
        <el-table-column prop="updatedAt" label="更新时间" :formatter="formatTimestamp"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { get } from '@/utils/request'

export default {
  name: 'PermissionList',
  setup() {
    const searchForm = reactive({
      name: '',
      sortBy: 'name', // 默认排序字段
      sortDesc: false,
      method: '' // HTTP方法筛选
    })
    const permissions = ref([])

    const formatTimestamp = (row, column, cellValue) => {
      if (!cellValue) return ''
      const date = new Date(cellValue * 1000) // 将秒转换为毫秒
      return date.toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' })
    }

    const searchPermissions = async () => {
      try {
        const params = {
          sortBy: searchForm.sortBy,
          sortDesc: searchForm.sortDesc,
          filter: 'method:' + searchForm.method
        }
        const res = await get('/auth/admin/permission/all',params)
        permissions.value = res.data
      } catch (error) {
        ElMessage.error('获取权限列表失败')
      }
    }

    const resetSearch = () => {
      searchForm.name = ''
      searchForm.sortBy = 'name'
      searchForm.sortDesc = false
      searchForm.method = ''
      searchPermissions()
    }

    onMounted(() => {
      searchPermissions()
    })

    return {
      searchForm,
      permissions,
      searchPermissions,
      resetSearch,
      formatTimestamp,
    }
  }
}
</script>

<style scoped>
.permission-list {
  height: 100%;
}
.mb-4 {
  margin-bottom: 1rem;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.button-area {
  display: flex;
  gap: 0.5rem;
}
</style>