import { get } from './request'

const CACHE_KEY = 'APP_CONSTANTS'
const CACHE_EXPIRY = 24 * 60 * 60 * 1000 // 24小时

const constantsMap = {
  classifyTypes: '/classify-types',
  topicTypes: '/topic-types',
  topicOptionTypes: '/topic-option-types',
  fileTypes: '/file-types',
  userIdentities: '/user-identities'
}

export async function fetchAndCacheConstants() {
  const cachedData = localStorage.getItem(CACHE_KEY)
  let constants = {}
  let needsFetch = false

  if (cachedData) {
    const { timestamp, data } = JSON.parse(cachedData)
    if (Date.now() - timestamp < CACHE_EXPIRY) {
      constants = data
      // 检查是否所有常量都被缓存
      needsFetch = Object.keys(constantsMap).some(key => !constants[key] || constants[key].length === 0)
    } else {
      needsFetch = true
    }
  } else {
    needsFetch = true
  }

  if (needsFetch) {
    for (const [key, url] of Object.entries(constantsMap)) {
      if (!constants[key] || constants[key].length === 0) {
        try {
          const response = await get(url)
          constants[key] = response.data
        } catch (error) {
          console.error(`获取 ${key} 失败:`, error)
        }
      }
    }

    localStorage.setItem(CACHE_KEY, JSON.stringify({
      timestamp: Date.now(),
      data: constants
    }))
  }

  return constants
}

export function getConstants() {
  const cachedData = localStorage.getItem(CACHE_KEY)
  if (cachedData) {
    const { data } = JSON.parse(cachedData)
    return data
  }
  return null
}

export function getConstantByKey(key) {
  const constants = getConstants()
  return constants ? constants[key] : null
}

export function getIdentities() {
  return getConstantByKey('userIdentities') || []
}

export function hasIdentity(requiredIdentity) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
  const userIdentity = userInfo.identity
  const identities = getIdentities()

  const requiredIdentityObj = identities.find(i => i.Key === requiredIdentity)
  if (!requiredIdentityObj) return false

  if (requiredIdentityObj.Value === -1) return true // IdentityAllUser
  if (requiredIdentity === 'IdentityAllAdmin') {
    const adminIdentities = identities.filter(i => i.Key === 'IdentityAdmin' || i.Key === 'IdentitySuper')
    return adminIdentities.some(i => i.Value === userIdentity)
  }
  return userIdentity === requiredIdentityObj.Value
}

export function isSuperAdmin() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
  const userIdentity = userInfo.identity
  const identities = getIdentities()
  const superAdminIdentity = identities.find(i => i.Key === 'IdentitySuper')
  return superAdminIdentity && userIdentity === superAdminIdentity.Value
}

// 在 getFileTypeValue 函数中添加错误处理和日志
export function getFileTypeValue(typeName) {
  const fileTypes = getConstantByKey('fileTypes')
  if (!fileTypes || fileTypes.length === 0) {
    console.error('fileTypes 为空或未加载:', fileTypes)
    return null
  }
  
  const type = fileTypes.find(t => t.key === typeName)
  if (!type) {
    console.error(`未找到文件类型 "${typeName}" 在:`, fileTypes)
    return null
  }
  
  return type.value
}
