<template>
  <div class="user-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="用户名">
              <el-input v-model="searchForm.username" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="searchForm.phone" placeholder="手机号"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="searchForm.email" placeholder="邮箱"></el-input>
            </el-form-item>
            <el-form-item label="角色">
              <el-select v-model="searchForm.role" placeholder="选择角色">
                <el-option v-for="role in roles" :key="role.id" :label="role.name" :value="role.id"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchUsers">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button type="success" @click="openCreateDialog">新增用户</el-button>
        </div>
      </div>
    </el-card>

    <el-card>
      <el-table :data="users" style="width: 100%">
        {{ users }}
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="userName" label="用户名"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="email" label="邮箱"></el-table-column>
        <el-table-column prop="roleName" label="角色"></el-table-column>
        <el-table-column label="操作" width="250">
          <template #default="scope">
            <el-button size="small" @click="editUser(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" @click="deleteUser(scope.row)" v-if="canDeleteUser(scope.row)">删除</el-button>
            <el-button size="small" type="warning" @click="configurePermissions(scope.row)">配置权限</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :total="total"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- 新增/编辑用户对话框 -->
    <el-dialog :title="dialogTitle" v-model="dialogVisible" width="50%">
      <el-form :model="userForm" label-width="120px">
        <el-form-item label="用户名">
          <el-input v-model="userForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="userForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="userForm.email"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="userForm.roleId">
            <el-option v-for="role in roles" :key="role.id" :label="role.name" :value="role.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitUser">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 配置用户权限对话框 -->
    <el-dialog title="配置用户权限" v-model="permissionDialogVisible" width="50%">
      <el-form :model="permissionForm">
        <el-form-item label="角色">
          <el-select v-model="permissionForm.roleId" @change="handleRoleChange">
            <el-option v-for="role in roles" :key="role.id" :label="role.name" :value="role.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权限">
          <el-checkbox-group v-model="permissionForm.permissions">
            <el-checkbox v-for="permission in allPermissions" :key="permission.id" :label="permission.id">
              {{ permission.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="permissionDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitPermissions">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, watch, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get, post, put, del } from '@/utils/request'
import { hasIdentity, isSuperAdmin } from '@/utils/constants'

export default {
  name: 'UserManagement',
  setup() {
    const searchForm = reactive({
      username: '',
      phone: '',
      email: '',
      role: ''
    })
    const users = ref([])
    const roles = ref([])
    const allPermissions = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const permissionDialogVisible = ref(false)
    const dialogTitle = ref('新增用户')
    const userForm = reactive({
      id: null,
      userName: '',
      phone: '',
      email: '',
      roleId: ''
    })
    const permissionForm = reactive({
      userId: null,
      roleId: '',
      permissions: []
    })

    const searchUsers = async () => {
      try {
        const res = await get('/auth/admin/user', {
          page: currentPage.value,
          pageSize: pageSize.value,
          username: searchForm.username,
          phone: searchForm.phone,
          email: searchForm.email,
          role: searchForm.role
        })
        console.log(res)
        users.value = res.data.list
        total.value = res.data.total
      } catch (error) {
        ElMessage.error('获取用户列表失败')
      }
    }

    const resetSearch = () => {
      Object.assign(searchForm, {
        username: '',
        phone: '',
        email: '',
        role: ''
      })
      searchUsers()
    }

    const openCreateDialog = () => {
      dialogTitle.value = '新增用户'
      Object.assign(userForm, {
        id: null,
        userName: '',
        phone: '',
        email: '',
        roleId: ''
      })
      dialogVisible.value = true
    }

    const editUser = (row) => {
      dialogTitle.value = '编辑用户'
      Object.assign(userForm, row)
      dialogVisible.value = true
    }

    const deleteUser = (row) => {
      ElMessageBox.confirm('确定要删除这个用户吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await del(`/auth/admin/user/${row.id}`)
          ElMessage.success('删除成功')
          searchUsers()
        } catch (error) {
          ElMessage.error('删除失败')
        }
      }).catch(() => {})
    }

    const submitUser = async () => {
      try {
        if (userForm.id) {
          await put(`/auth/admin/user/${userForm.id}`, userForm)
          ElMessage.success('更新成功')
        } else {
          await post('/auth/admin/user', userForm)
          ElMessage.success('创建成功')
        }
        dialogVisible.value = false
        searchUsers()
      } catch (error) {
        ElMessage.error('操作失败')
      }
    }

    const configurePermissions = async (user) => {
      permissionForm.userId = user.id
      permissionForm.roleId = user.roleId
      try {
        const res = await get('/auth/admin/permission/user-permissions', { user_id: user.id })
        permissionForm.permissions = res.data.map(p => p.id)
        permissionDialogVisible.value = true
      } catch (error) {
        ElMessage.error('获取用户权限失败')
      }
    }

    const handleRoleChange = async () => {
      try {
        const res = await get('/auth/admin/permission/role-permissions', { role_id: permissionForm.roleId })
        permissionForm.permissions = res.data.map(p => p.id)
      } catch (error) {
        ElMessage.error('获取角色权限失败')
      }
    }

    const submitPermissions = async () => {
      try {
        await post('/auth/admin/permission/assign-role', {
          user_id: permissionForm.userId,
          role_id: permissionForm.roleId
        })
        for (const permissionId of permissionForm.permissions) {
          await post('/auth/admin/permission/assign-permission', {
            role_id: permissionForm.roleId,
            permission_id: permissionId
          })
        }
        ElMessage.success('权限配置成功')
        permissionDialogVisible.value = false
        searchUsers()
      } catch (error) {
        ElMessage.error('权限配置失败')
      }
    }

    const handleSizeChange = (val) => {
      pageSize.value = val
      currentPage.value = 1
      searchUsers()
    }

    const handleCurrentChange = (val) => {
      currentPage.value = val
      searchUsers()
    }

    const fetchRoles = async () => {
      try {
        const res = await get('/auth/admin/permission/roles')
        roles.value = res.data
      } catch (error) {
        ElMessage.error('获取角色列表失败')
      }
    }

    const fetchAllPermissions = async () => {
      try {
        const res = await get('/auth/admin/permission/all')
        allPermissions.value = res.data
      } catch (error) {
        ElMessage.error('获取权限列表失败')
      }
    }

    const canDeleteUser = (user) => {
      return isSuperAdmin() || (hasIdentity('IdentityAdmin') && !hasIdentity('IdentitySuper', user.identity))
    }

    onMounted(() => {
      searchUsers()
      fetchRoles()
      fetchAllPermissions()
    })

    watch([currentPage, pageSize], () => {
      searchUsers()
    })

    return {
      searchForm,
      users,
      roles,
      allPermissions,
      currentPage,
      pageSize,
      total,
      dialogVisible,
      permissionDialogVisible,
      dialogTitle,
      userForm,
      permissionForm,
      searchUsers,
      resetSearch,
      openCreateDialog,
      editUser,
      deleteUser,
      submitUser,
      configurePermissions,
      handleRoleChange,
      submitPermissions,
      handleSizeChange,
      handleCurrentChange,
      canDeleteUser,
      hasIdentity,
    }
  }
}
</script>

<style scoped>
.user-management {
  height: 100%;
}
.mb-4 {
  margin-bottom: 1rem;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.button-area {
  display: flex;
  gap: 0.5rem;
}
.pagination-container {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
</style>