<template>
  <div class="app-container">
    <router-view></router-view>
    <IcpFooter />
  </div>
</template>

<script>
import { fetchAndCacheConstants } from '@/utils/constants';
import IcpFooter from '@/components/IcpFooter.vue';

export default {
  name: 'app',
  components: {
    IcpFooter
  },
  async created() {
    await fetchAndCacheConstants();
    this.$globalProperties = {
      isMobile: this._isMobile()
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
}
</script>

<style>
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  flex: 1;
}
</style>
